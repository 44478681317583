import Web3 from 'web3'

const DApps = {
  web3: new Web3(),
  instances: {}
}

DApps.web3.setProvider(window.ethereum)

setInterval(() => {
  const pluginInstalled = typeof window.ethereum !== 'undefined'
  const walletConnected = pluginInstalled && window.ethereum.selectedAddress !== null
  Object.values(DApps.instances).forEach((instance) => {
    instance.pluginInstalled = pluginInstalled
    instance.walletConnected = walletConnected
    if (!walletConnected) { return }
    instance.selectedWalletAddress = window.ethereum.selectedAddress
    instance.refresh()
  })
}, 1000)

window.da = DApps

export default {
  props: {
    contract: {
      type: String,
      defaults: ''
    },
    instanceId: {
      type: String
    }
  },
  computed: {
    contractAccessed () {
      return this.smartContract !== null
    }
  },
  data: () => ({
    id: '',
    pluginInstalled: false,
    walletConnected: false,
    selectedWalletAddress: '',
    smartContract: null
  }),
  mounted () {
    this.initInstanceId()
    if (DApps.instances[this.id]) {
      throw new Error(`Instance ID already exists ${this.id}`)
    }
    DApps.instances[this.id] = this
    this.$dapps = DApps
  },
  methods: {
    initInstanceId () {
      if (!this.instanceId) {
        const i = Object.keys(DApps.instances).filter(key => key.startsWith(this.contract)).length
        this.id = `${this.contract}-${i}`
      } else {
        this.id = this.instanceId
      }
    },
    createSmartContract: (abi, address) => new DApps.web3.eth.Contract(abi, address),
    async initContract () {
      const abi = await this.abi()
      this.smartContract = this.createSmartContract(abi, this.contract)
      await this.onInitContract()
    },
    async onInitContract () {},
    async abi () { return '{}' },
    async refresh () {
      if (!this.contractAccessed) {
        await this.initContract()
      }
      await this.onRefresh()
    },
    async onRefresh () {},
    requestConnectWallet () {
      if (!this.pluginInstalled) {
        return this.requestInstallMetamask()
      }
      this.$dapps.web3.eth.requestAccounts()
    },
    requestInstallMetamask () {
      window.open('https://metamask.io/download/')
    },
    async getWalletBalance () {
      return await this.$dapps.web3.eth.getBalance(this.selectedWalletAddress)
    },
    batchRequest (requests) {
      console.log('batchRequest', requests)
      const batch = new this.$dapps.web3.BatchRequest()
      requests.forEach((r) => {
        batch.add(r.request, r.callback)
      })
      console.log(batch)
      window.bt = batch
      return batch.execute()
    }
  }
}
