import 'quasar/dist/quasar.css'
import iconSet from 'quasar/icon-set/material-icons.js'
import '@quasar/extras/material-icons/material-icons.css'
import Loading from 'quasar/src/plugins/Loading.js';

// To be used on app.use(Quasar, { ... })
export default {
  config: { loading: { /* Loading defaults */ } },
  plugins: {
    Loading
  },
  iconSet: iconSet
}